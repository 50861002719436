import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import { FluidObject } from 'gatsby-image'

import { UseRecipientsQuery } from '../graphqlTypes'

const getLastName = (name: string) => {
  const parts = name.trim().split(' ')
  return parts[parts.length - 1]
}

export const useRecipients = () => {
  const queryData = useStaticQuery<UseRecipientsQuery>(graphql`
    query UseRecipients {
      allPrismicRecipient {
        nodes {
          id
          data {
            name {
              text
            }
            occupation {
              text
            }
            location {
              text
            }
            nominator {
              text
            }
            message_excerpt {
              text
            }
            message {
              text
              html
            }
            photo {
              alt
              fluid(maxWidth: 400) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  `)

  return React.useMemo(
    () =>
      queryData.allPrismicRecipient.nodes
        .map(node => ({
          id: node?.id,
          name: node?.data?.name?.text ?? '',
          occupation: node?.data?.occupation?.text,
          location: node?.data?.location?.text,
          nominator: node?.data?.nominator?.text,
          messageExcerpt: node?.data?.message_excerpt?.text,
          messageHTML: getRichText(node?.data?.message),
          photoFluid: node?.data?.photo?.fluid as FluidObject,
          photoAlt: node?.data?.photo?.alt,
        }))
        .sort((a, b) => {
          const lastName = getLastName(a.name).localeCompare(
            getLastName(b.name),
          )
          if (lastName !== 0) return lastName

          return a.name.localeCompare(b.name)
        }),
    [queryData],
  )
}
