import React from 'react'
import { useField } from 'formik'

import { Text } from './Text'
import { t, mq, scale } from '../theme'
import { ErrorMessage } from './ErrorMessage'

type CheckboxProps = {
  label?: string
  name: string
  className?: string
  children?: React.ReactNode
}

export const Checkbox = ({
  name,
  label,
  className,
  children,
}: CheckboxProps) => {
  const [field, meta] = useField(name)

  return (
    <div className={className}>
      <label
        css={{
          cursor: 'pointer',
          userSelect: 'none',
          display: 'flex',
          alignItems: 'center',
          margin: '0 -.5rem',
        }}
      >
        <input
          type="checkbox"
          id={name}
          name={name}
          {...field}
          css={{
            display: 'none',
          }}
        />
        <div
          css={{
            border: '1px solid',
            borderColor: t.colors.tan[80],
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            color: 'transparent',
            backgroundColor: t.colors.white,
            fontSize: '.7rem',
            justifyContent: 'center',
            lineHeight: '.3rem',
            fontWeight: t.fontWeights.bold,
            transition: 'background .2s ease',
            width: '1.125rem',
            height: '1.125rem',
            fontFamily: t.fonts.sans,

            'input:checked + &': {
              backgroundColor: t.colors.red[40],
              color: t.colors.white,
            },
          }}
        >
          <span>&#10003;</span>
        </div>
        <Text
          css={mq({
            fontSize: scale('1rem', '1.1rem', 'font'),
            fontWeight: t.fontWeights.medium,
            marginLeft: scale('.5rem', '.65rem', 'space'),
          })}
        >
          {children || label}
        </Text>
      </label>

      {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  )
}
