import React from 'react'
import GatsbyImage from 'gatsby-image'
import { graphql } from 'gatsby'
import { Flex } from '@walltowall/system'

import { t, mq, scale } from '../theme'
import { MapDataToPropsArgs } from 'types'
import { PageBodyImageWithTextFragment } from '../graphqlTypes'

import { Heading } from '../components/Heading'
import { Text } from '../components/Text'

export type PageBodyImageWithTextProps = Partial<
  ReturnType<typeof mapDataToProps>
>

export const PageBodyImageWithText = ({
  title,
  text,
  imageFluid,
  imageAlt,
}: PageBodyImageWithTextProps) => (
  <div
    css={mq({
      maxWidth: t.sizes.x,
      backgroundColor: t.colors.white,
      color: t.colors.maroon[0],
      margin: '0 auto',
    })}
  >
    <Flex
      css={mq({
        flexDirection: ['column', 'row-reverse'],
      })}
    >
      <div
        css={mq({
          paddingTop: scale('2.75rem', '5rem', 'space'),
          paddingBottom: scale('2.75rem', '5rem', 'space'),
          paddingLeft: scale('2.75rem', '3.25rem', 'space'),
          paddingRight: scale('2.75rem', '3.25rem', 'space'),
          flexShrink: 0,
          width: [null, '50%'],
          alignSelf: [null, 'center'],
        })}
      >
        <Heading
          css={mq({
            marginBottom: scale('2.75rem', '2.75rem', 'space'),
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '20ch',
            textAlign: 'center',
          })}
        >
          {title}
        </Heading>

        <Text
          css={{
            maxWidth: '60ch',
            margin: '0 auto',
            textAlign: 'center',
          }}
        >
          {text}
        </Text>
      </div>

      <GatsbyImage
        fluid={imageFluid}
        alt={imageAlt}
        css={mq({
          minHeight: '100%',
          flexShrink: 0,
          width: [null, '50%'],
        })}
        imgStyle={{ objectFit: 'cover', objectPosition: 'top center' }}
      />
    </Flex>
  </div>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyImageWithTextFragment>) => ({
  text: data.primary?.text?.text,
  title: data.primary?.title1?.text,
  imageFluid: data.primary?.image?.fluid,
  imageAlt: data.primary?.image?.alt,
})

export const query = graphql`
  fragment PageBodyImageWithText on PrismicPageBodyImageWithText {
    primary {
      title1 {
        text
      }
      text {
        text
      }
      image {
        alt
        fluid(maxWidth: 700) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`
PageBodyImageWithText.mapDataToProps = mapDataToProps
export default PageBodyImageWithText
