import React from 'react'
import {
  BoundedView as SystemBoundedView,
  BoundedViewProps as SystemBoundedViewProps,
} from '@walltowall/system'

import { t, mq, scale } from '../theme'

export const BoundedView = <E extends React.ElementType>({
  ...props
}: SystemBoundedViewProps<E>) => (
  <SystemBoundedView
    as="div"
    maxWidth={t.sizes.x}
    {...props}
    css={mq({
      paddingTop: scale('3.5rem', '5rem', 'space'),
      paddingBottom: scale('3.5rem', '5rem', 'space'),
      paddingLeft: scale('1.75rem', '3rem', 'space'),
      paddingRight: scale('1.75rem', '3rem', 'space'),
      margin: '0 auto',
      width: '100%',
    })}
  />
)
