import React, { useState } from 'react'
import { Flex } from '@walltowall/system'
import { Formik, Form as FormikForm } from 'formik'
import { ShapeErrors } from 'nope-validator/lib/umd/types'
import { object, string, boolean } from 'nope-validator'
import { postNetlifyForm } from '@walltowall/helpers'

import { t, mq, scale } from '../theme'

import { BoundedView } from '../components/BoundedView'
import { GreenBox } from '../components/GreenBox'
import { TextInput } from '../components/TextInput'
import { TextArea } from '../components/TextArea'
import { Text } from '../components/Text'
import { Button } from '../components/Button'
import { Checkbox } from '../components/Checkbox'
import { Heading } from '../components/Heading'
import { Anchor } from '../components/Anchor'

const requiredString = () =>
  string().required().atLeast(1, 'This field is required.')

const FormRow = ({ children }: { children: React.ReactNode }) => {
  return <Flex css={mq({ flexDirection: ['column', 'row'] })}>{children}</Flex>
}

const initialValues = {
  yourFirstName: '',
  yourLastName: '',
  yourEmailAddress: '',
  heroFirstName: '',
  heroLastName: '',
  heroContact: '',
  message: '',
  consent: false,
  botField: '',
}
const formSchema = object().shape({
  yourFirstName: requiredString(),
  yourLastName: requiredString(),
  yourEmailAddress: requiredString().email(),
  heroFirstName: requiredString(),
  heroLastName: requiredString(),
  heroContact: string(),
  message: requiredString().atMost(1000),
  consent: boolean().true('Agreement is required to nominate your hero.'),
  botField: string(),
})

const FORM_NAME = 'Nominate a Hometown Hero'

export const PageBodyForm = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const onSubmit = async (values: typeof initialValues) => {
    if (hasSubmitted) return

    const res = await postNetlifyForm(FORM_NAME, values)
    if (res) setHasSubmitted(true)
  }

  return (
    <BoundedView
      maxWidth={t.sizes.s}
      css={{
        backgroundColor: t.colors.tan[90],
        maxWidth: t.sizes.x,
      }}
    >
      <Flex css={{ flexDirection: 'column', alignItems: 'center' }}>
        <GreenBox
          css={mq({ marginBottom: scale('2.5rem', '2.5rem', 'space') })}
        >
          Nominate a Hometown&nbsp;Hero
        </GreenBox>
        <Formik
          initialValues={initialValues}
          validate={values => formSchema.validate(values) as ShapeErrors}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={onSubmit}
        >
          {({ values }) =>
            !hasSubmitted ? (
              <>
                <FormikForm
                  name={FORM_NAME}
                  data-netlify={true}
                  netlify-honeypot="botField"
                  css={mq({ marginBottom: scale('2.5rem', '2.5rem', 'space') })}
                >
                  <input type="hidden" name={FORM_NAME} value={FORM_NAME} />

                  <TextInput
                    label="botField"
                    name="botField"
                    css={{ display: 'none' }}
                  />

                  <FormRow>
                    <TextInput
                      label="Your First Name"
                      placeholder="First Name"
                      name="yourFirstName"
                      css={mq({ marginRight: [null, '2rem'] })}
                    />
                    <TextInput
                      label="Your Last Name"
                      placeholder="Last Name"
                      name="yourLastName"
                    />
                  </FormRow>

                  <TextInput
                    label="Your Email Address"
                    placeholder="Email Address"
                    name="yourEmailAddress"
                  />

                  <FormRow>
                    <TextInput
                      label="Hometown Hero's First Name"
                      placeholder="First Name"
                      name="heroFirstName"
                      css={mq({ marginRight: [null, '2rem'] })}
                    />
                    <TextInput
                      label="Hometown Hero's Last Name"
                      placeholder="Last Name"
                      name="heroLastName"
                    />
                  </FormRow>

                  <TextInput
                    label="Hometown Hero's Email"
                    placeholder="Email"
                    name="heroContact"
                  />

                  <TextArea
                    label="Tell us about your Hometown Hero. How have they gone above and beyond to serve their community during this time of&nbsp;crisis?"
                    name="message"
                    css={mq({
                      marginBottom: scale('1.75rem', '1.75rem', 'space'),
                    })}
                  />

                  <Checkbox
                    name="consent"
                    label="I agree to Consent"
                    css={mq({
                      marginBottom: scale('3rem', '3rem', 'space'),
                    })}
                  >
                    I agree and consent to the Hometown Heroes{' '}
                    <Anchor
                      as="a"
                      href="/hnb-hometown-heroes-official-rules.pdf"
                      target="_blank"
                    >
                      official rules
                    </Anchor>
                    .
                  </Checkbox>

                  <Button
                    type="submit"
                    css={mq({
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    })}
                  >
                    Submit
                  </Button>
                </FormikForm>
                <Text
                  css={mq({
                    textAlign: 'center',
                    fontSize: scale('0.9rem', '0.9rem', 'font'),
                    fontWeight: t.fontWeights.normal,
                  })}
                >
                  To be eligible for nomination, your Hometown Hero must be a
                  current Hawai’i resident, 18 years of age or older with a
                  valid Hawai’i mailing address. Nominators are not required to
                  be a Hawai’i resident, but must be 18 years of age or older.
                </Text>
              </>
            ) : (
              <div css={{ textAlign: 'center' }}>
                <Heading
                  id="form-success"
                  css={mq({
                    marginBottom: scale('2rem', '2rem', 'space'),
                  })}
                >
                  Thank you for your nomination!
                </Heading>
                <Text>
                  We will send an email to{' '}
                  <strong
                    css={{
                      fontWeight: t.fontWeights.medium,
                    }}
                  >
                    {values.yourEmailAddress}
                  </strong>{' '}
                  if{' '}
                  <strong
                    css={{
                      fontWeight: t.fontWeights.medium,
                    }}
                  >
                    {values.heroFirstName} {values.heroLastName}
                  </strong>{' '}
                  is selected as a recipient.
                </Text>
              </div>
            )
          }
        </Formik>
      </Flex>
    </BoundedView>
  )
}
