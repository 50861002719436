import React, { ButtonHTMLAttributes } from 'react'

import { t } from '../theme'
import { ClippedView } from './ClippedView'

const variants = {
  red: {
    color: t.colors.white,
    colorHover: t.colors.white,
    backgroundImage: `linear-gradient(to bottom, ${t.colors.red[40]}, ${t.colors.red[10]})`,
  },
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants
  children: string
}

export const Button = ({
  variant: variantName = 'red',
  children,
  disabled = false,
  ...props
}: ButtonProps) => {
  const { colorHover, ...variant } = variants[variantName]

  return (
    <button
      css={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        borderRadius: '100em',
        fontFamily: t.fonts.sansCond,
        fontSize: '1.125rem',
        fontWeight: t.fontWeights.bold,
        lineHeight: t.lineHeights.solid,
        minWidth: '8rem',
        opacity: disabled ? 0.5 : 1,
        padding: '.75rem 2rem',
        textAlign: 'center',
        backgroundPositionY: '0%',
        backgroundSize: '100% 150%',
        transitionProperty: 'color, background-position-y',
        ...variant,
        ...t.textStyles.trackedCapsWide,

        '&:hover, &:focus': {
          backgroundPositionY: '95%',
          color: colorHover,
        },
      }}
      {...props}
    >
      <ClippedView clipTop=".15em" clipBottom=".15em">
        {children}
      </ClippedView>
    </button>
  )
}
