import React from 'react'

import { t, mq } from '../theme'

import { ReactComponent as AssetLogoTextSVG } from '../assets/logo-text.svg'
import { ReactComponent as AssetLogoIconSVG } from '../assets/logo-icon.svg'
import AssetTextureWarrior from '../assets/texture-warrior.svg'
import { Anchor } from '../components/Anchor'

export type PageBodyHeaderProps = {}

export const PageBodyHeader = ({}: PageBodyHeaderProps) => {
  return (
    <header
      css={{
        display: 'flex',
        flexDirection: 'column',
        zIndex: t.zIndices.header,
        pointerEvents: 'none',
      }}
    >
      <div
        css={{
          backgroundImage: `url(${AssetTextureWarrior}), linear-gradient(to bottom, ${t.colors.red[40]}, ${t.colors.red[30]})`,
          backgroundPosition: 'bottom left',
          backgroundSize: 'cover',
          position: 'relative',
          padding: '1.5rem 1rem',
          zIndex: 1,
          boxShadow:
            'rgba(66, 49, 50, 0.25) 0px 2px 4px, rgba(66, 49, 50, 0.1) 0px 1px 0px',
          pointerEvents: 'auto',
        }}
      >
        <div
          css={mq({
            paddingLeft: [null, null, null, null, '1rem'],
            paddingRight: [null, null, null, null, '1rem'],
            maxWidth: t.sizes.x,
            margin: '0 auto',
          })}
        >
          <Anchor
            href="https://hawaiinational.bank"
            css={{
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
            }}
          >
            <AssetLogoIconSVG
              css={mq({
                display: ['none', 'block'],
                left: 0,
                marginRight: '1rem',
                position: 'absolute',
                top: '50%',
                width: '5rem',
                height: '6rem',
                transform: 'translateY(-45%)',
              })}
            />
            <AssetLogoTextSVG
              css={mq({
                width: '16rem',
                height: '0.8125rem',
                marginLeft: [null, '5.5rem'],
              })}
            />
          </Anchor>
        </div>
      </div>
    </header>
  )
}
