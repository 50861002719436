import React from 'react'

import { t } from '../theme'
import { Link, LinkProps } from './Link'

export type AnchorProps = LinkProps & {
  as?: 'a'
  hoverColor?: string
}

export const Anchor = ({ hoverColor, ...props }: AnchorProps) => (
  <Link
    {...props}
    css={{
      color: t.colors.red['30-brand'],
      textDecoration: 'underline',
      transitionDuration: t.transitionDurations.normal,
      transitionProperty: 'color',
    }}
  />
)
