import { graphql } from 'gatsby'

// 1. Import your slice
import { PageBodyHero } from './PageBodyHero'
import { PageBodyImageWithText } from './PageBodyImageWithText'
import { PageBodyColoredBoxes } from './PageBodyColoredBoxes'
import { PageBodyForm } from './PageBodyForm'
import { PageBodyFaq } from './PageBodyFaq'
import { PageBodyTerms } from './PageBodyTerms'
import { PageBodyHeader } from './PageBodyHeader'
import { PageBodyFooter } from './PageBodyFooter'
import { PageBodyRecipients } from './PageBodyRecipients'

// 2. Add your slice
export const slices = {
  PageBodyHeader,
  PageBodyHero,
  PageBodyImageWithText,
  PageBodyRecipients,
  PageBodyColoredBoxes,
  PageBodyForm,
  PageBodyFaq,
  PageBodyTerms,
  PageBodyFooter,
}

// 3. Add your slice fragment
export const fragment = graphql`
  fragment SlicesPageBody on PrismicPageBodySlicesType {
    ...PageBodyHero
    ...PageBodyImageWithText
    ...PageBodyRecipients
    ...PageBodyColoredBoxes
    ...PageBodyFaq
    ...PageBodyTerms

    # The following slices do not have fragments:
    #...PageLayoutFooter
    #...PageLayoutHeader
  }
`
