import React from 'react'

import { t, mq } from '../theme'
import { ClippedView, ClippedViewProps } from './ClippedView'

const variants = {
  sans: {
    fontFamily: t.fonts.sans,
    fontWeight: t.fontWeights.normal,
    lineHeight: t.lineHeights.copy,
    clipTop: '0.37em',
    clipBottom: '0.37em',
  },
} as const

type TextAs = 'p' | 'span' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'li' | 'small'

type TextProps = {
  as?: TextAs
  variant?: keyof typeof variants
  className?: string
  children?: React.ReactNode
  clipTop?: ClippedViewProps<TextAs>['clipTop']
  clipBottom?: ClippedViewProps<TextAs>['clipBottom']
}

export const Text = ({
  as = 'p',
  variant: variantName = 'sans',
  children,
  className,
}: TextProps) => {
  const { clipBottom, clipTop, ...variant } = variants[variantName]

  return (
    <ClippedView
      as={as}
      clipBottom={clipBottom}
      clipTop={clipTop}
      className={className}
      css={mq({
        fontFamily: variant.fontFamily,
        fontWeight: variant.fontWeight,
        lineHeight: variant.lineHeight,
      })}
    >
      {children}
    </ClippedView>
  )
}
