import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Expand from 'react-expand-animated'
import { Flex } from '@walltowall/system'
import { getRichText } from '@walltowall/helpers'

import { t, mq, scale } from '../theme'
import { MapDataToPropsArgs } from '../types'
import { PageBodyFaqFragment } from '../graphqlTypes'

import { BoundedView } from '../components/BoundedView'
import { GreenBox } from '../components/GreenBox'
import { Text } from '../components/Text'
import { Heading } from '../components/Heading'
import { Anchor } from '../components/Anchor'
import { HTMLContent } from '../components/HTMLContent'

import { ReactComponent as AssetPlusSVG } from '../assets/icon-plus.svg'
import { ReactComponent as AssetMinusSVG } from '../assets/icon-minus.svg'

const Paragraph = ({ children }: { children: React.ReactNode }) => (
  <Text
    css={mq({
      marginBottom: scale('1.25rem', '1.5rem', 'space'),
      ...t.boxStyles.lastNoMargin,
    })}
  >
    {children}
  </Text>
)

type QuestionProps = {
  question: string
  answer: React.ReactNode
}

const Question = ({ question, answer }: QuestionProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpand = () => setIsExpanded(state => !state)

  return (
    <li
      css={{
        borderBottom: `1px solid ${t.colors.gray[70]}`,
      }}
    >
      <Flex
        as="button"
        onClick={toggleExpand}
        css={mq({
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: scale('1.25rem', '1.75rem', 'space'),
          paddingBottom: scale('1.25rem', '1.75rem', 'space'),
        })}
      >
        <Heading
          as="h3"
          css={mq({
            fontSize: scale('1.2rem', '1.5rem', 'font'),
            marginRight: scale('1rem', '2rem', 'space'),
          })}
        >
          {question}
        </Heading>

        {isExpanded ? (
          <AssetMinusSVG
            css={mq({
              width: scale('0.875rem', '1rem'),
            })}
          />
        ) : (
          <AssetPlusSVG
            css={mq({
              width: scale('0.875rem', '1rem'),
            })}
          />
        )}
      </Flex>
      <Expand open={isExpanded} duration={100}>
        <div css={mq({ paddingBottom: scale('1.25rem', '1.75rem', 'space') })}>
          {answer}
        </div>
      </Expand>
    </li>
  )
}

export type PageBodyFaqProps = Partial<ReturnType<typeof mapDataToProps>>

export const PageBodyFaq = ({ faqs, ...props }: PageBodyFaqProps) => (
  <BoundedView
    maxWidth={t.sizes.s}
    css={mq({
      backgroundColor: t.colors.white,
      maxWidth: t.sizes.x,
      paddingBottom: scale('5rem', '7rem', 'space'),
    })}
    {...props}
  >
    <Flex
      css={mq({
        flexDirection: 'column',
        alignItems: 'center',
      })}
    >
      <GreenBox
        css={mq({
          marginBottom: scale('2rem', '2rem', 'space'),
        })}
      >
        FAQS
      </GreenBox>
      <ul
        css={{
          borderTop: `1px solid ${t.colors.gray[70]}`,
        }}
      >
        {faqs.map(faq => (
          <Question
            key={faq.question}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </ul>
    </Flex>
  </BoundedView>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyFaqFragment>) => ({
  faqs: data?.items?.map(item => ({
    question: item?.question?.text,
    answer: (
      <HTMLContent
        html={getRichText(item?.answer)}
        componentOverrides={{
          a: () => props => <Anchor {...props} />,
          p: () => props => <Paragraph {...props} />,
        }}
      />
    ),
  })),
})

export const query = graphql`
  fragment PageBodyFaq on PrismicPageBodyFaq {
    items {
      answer {
        html
        text
      }
      question {
        html
        text
      }
    }
  }
`

PageBodyFaq.mapDataToProps = mapDataToProps
export default PageBodyFaq
