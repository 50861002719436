import React from 'react'
import { graphql, PageProps } from 'gatsby'
import MapSlicesToComponents from '@walltowall/react-map-slices-to-components'

import { mq, scale } from '../theme'
import { PageTemplateQuery } from 'graphqlTypes'

import { Layout } from '../components/Layout'

import { slices } from '../slices/PageBody'

const slicesMiddleware = <T,>(list: T[]) => [
  { __typename: 'PageBodyHeader', id: 'header' },
  ...list,
  { __typename: 'PageBodyFooter', id: 'footer' },
]

const PageTemplate = ({ data }: PageProps<PageTemplateQuery>) => {
  const page = data?.prismicPage
  return (
    <Layout>
      <main>
        <MapSlicesToComponents
          list={page?.data?.body}
          map={slices}
          listMiddleware={slicesMiddleware}
          mapOverrides={{
            PageBodyFaq: Comp => props => {
              return (
                <Comp {...props} css={mq({ paddingBottom: scale(0, 0) })} />
              )
            },
          }}
        />
      </main>
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query PageTemplate($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        body {
          __typename
          ...SlicesPageBody
        }
      }
    }
  }
`
