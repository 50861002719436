import React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { Flex, AspectRatio } from '@walltowall/system'
import { keyframes } from '@emotion/core'

import { ReactComponent as AssetHeroTextLogoSVG } from '../assets/hero-logo.svg'
import { t, mq, scale } from '../theme'
import { MapDataToPropsArgs } from '../types'
import { PageBodyHeroFragment } from '../graphqlTypes'

import { BoundedView } from '../components/BoundedView'

const slideLeft = keyframes`
  from { transform: translate3d(0%, 0, 0) }
  to { transform: translate3d(-50%, 0, 0) }
`

type AnimatedHeroImageProps = {
  imageFluid: FluidObject
  imageAlt?: string
  imageWidth: number
  imageHeight: number
  heightScaleMin: string
  heightScaleMax: string
  duration: string
}

const AnimatedHeroImage = ({
  imageFluid,
  imageAlt,
  imageWidth,
  imageHeight,
  heightScaleMin,
  heightScaleMax,
  duration,
}: AnimatedHeroImageProps) => {
  const heightScale = scale(heightScaleMin, heightScaleMax)
  const widthScale = heightScale.map(
    y => (imageWidth / imageHeight) * Number.parseFloat(y as string) + 'rem',
  )

  return (
    <div css={mq({ height: heightScale, position: 'relative' })}>
      <Flex
        css={{
          animationName: slideLeft,
          animationDuration: duration,
          animationTimingFunction: 'linear',
          animationIterationCount: 'infinite',
          position: 'absolute',
          willChange: 'transform',
        }}
      >
        {Array(2)
          .fill(undefined)
          .map((_, i) => (
            <AspectRatio
              key={i}
              x={imageWidth}
              y={imageHeight}
              css={mq({
                flex: '0 0 auto',
                width: widthScale,
              })}
            >
              <GatsbyImage fluid={imageFluid} alt={imageAlt} />
            </AspectRatio>
          ))}
      </Flex>
    </div>
  )
}

export type PageBodyHeroProps = Partial<ReturnType<typeof mapDataToProps>>

export const PageBodyHero = ({ imageFluid, imageAlt }: PageBodyHeroProps) => {
  return (
    <BoundedView
      maxWidth={t.sizes.x}
      css={mq({
        padding: scale('0', '0'),
        position: 'relative',
      })}
    >
      <div css={{ overflow: 'hidden', height: '100%' }}>
        {imageFluid && (
          <AnimatedHeroImage
            imageFluid={imageFluid}
            imageAlt={imageAlt}
            imageWidth={2560}
            imageHeight={1021}
            heightScaleMin="20rem"
            heightScaleMax="50rem"
            duration="100s"
          />
        )}
        <Flex
          css={mq({
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          })}
        >
          <AssetHeroTextLogoSVG
            css={mq({
              display: 'block',
              maxWidth: '40rem',
              width: ['65%', null, '50%'],
            })}
          />
        </Flex>
      </div>
    </BoundedView>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyHeroFragment>) => ({
  imageFluid: data.primary?.image?.fluid,
  imageAlt: data.primary?.image?.alt,
})

export const query = graphql`
  fragment PageBodyHero on PrismicPageBodyHero {
    primary {
      image {
        alt
        fluid(maxWidth: 2000) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

PageBodyHero.mapDataToProps = mapDataToProps
export default PageBodyHero
