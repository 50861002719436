import React from 'react'
import { castArray } from 'tiny-compose-fns'
import { View, ViewProps } from '@walltowall/system'

import { mq } from '../theme'

export type ClippedViewProps<E extends React.ElementType> = {
  clipTop?: string | string[]
  clipBottom?: string | string[]
  debug?: boolean
} & ViewProps<E>

export const ClippedView = <E extends React.ElementType>({
  clipTop,
  clipBottom,
  debug = false,
  ...props
}: ClippedViewProps<E>) => {
  return (
    <View
      as="div"
      {...props}
      css={mq({
        boxShadow: debug ? '0 0 0 0.5px #f0f' : undefined,
        '&::before': {
          content: '""',
          display: 'flex',
          marginBottom: castArray(clipTop).map(x => '-' + x),
        },
        '&::after': {
          content: '""',
          display: 'flex',
          marginTop: castArray(clipBottom).map(x => '-' + x),
        },
      })}
    />
  )
}
