import React, { useMemo, useState } from 'react'
import { graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import VisuallyHidden from '@reach/visually-hidden'
import { Flex } from '@walltowall/system'
import { getRichText } from '@walltowall/helpers'
import { negateScale } from 'styled-system-scale'

import { t, mq, scale } from '../theme'
import { MapDataToPropsArgs } from '../types'
import { PageBodyRecipientsFragment } from '../graphqlTypes'
import { useRecipients } from '../hooks/useRecipients'

import { GreenBox } from '../components/GreenBox'
import { Text } from '../components/Text'
import { HTMLContent } from '../components/HTMLContent'
import { BoundedView } from '../components/BoundedView'
import { Button } from '../components/Button'

import { ReactComponent as AssetLocationSVG } from '../assets/icon-location.svg'
import { ReactComponent as AssetQuoteSVG } from '../assets/icon-quote.svg'
import { ReactComponent as AssetHeroTextLogoSVG } from '../assets/hero-logo.svg'
import { ReactComponent as AssetCloseSVG } from '../assets/icon-close.svg'
import { ReactComponent as AssetSearchSVG } from '../assets/icon-search.svg'
import { Heading } from '../components/Heading'

// create dynamic background color based on card order
const getBackgroundColor = (index: number) => {
  const colors = [
    t.colors.pink['40'],
    t.colors.green['40'],
    t.colors.green['70'],

    t.colors.green['70'],
    t.colors.pink['40'],
    t.colors.green['40'],
  ]

  return colors[(index - 1) % colors.length]
}

interface ModalData extends CardBaseInfoProps {
  backgroundColorVariant?: string
  descriptionHTML?: string
}
interface ModalProps {
  modalData?: ModalData
  showDialog: boolean
  closeModal: () => void
}

const Modal = ({ modalData, showDialog, closeModal }: ModalProps) => {
  return (
    <div>
      <DialogOverlay
        isOpen={showDialog}
        onDismiss={closeModal}
        css={mq({
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          padding: scale('1rem', '3rem', 'space'),
        })}
      >
        <div
          css={{
            minHeight: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <DialogContent
            aria-label="Detailed information about hometown hero nominated"
            css={mq({
              transform: 'translate(-50%, -50%)',
              top: '50%',
              left: '50%',
              position: 'absolute',
              padding: 0,
              margin: 0,
              width: '70rem',
              maxWidth: '100%',
            })}
          >
            <div
              css={mq({
                display: 'flex',
                flexDirection: ['column', 'row'],
                padding: scale('1.5rem', '3rem', 'space'),
                paddingRight: ['1.5rem', '3rem', '4rem', '4rem'],
                position: 'relative',
              })}
            >
              <div
                css={{
                  pointerEvents: 'none',
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: modalData?.backgroundColorVariant,
                  opacity: 0.7,
                }}
              />
              <CardBaseInfo
                name={modalData?.name}
                occupation={modalData?.occupation}
                location={modalData?.location}
                nominator={modalData?.nominator}
                css={mq({
                  color: 'white',
                  position: 'relative',
                  flexBasis: [null, '33%'],
                  paddingRight: [0, '2rem'],
                })}
              />
              <Flex
                css={mq({
                  flexDirection: 'column',
                  backgroundColor: t.colors.white,
                  borderRadius: '10px',
                  padding: scale('1.5rem', '2rem', 'space'),
                  margin: ['2rem 0', 0],
                  position: 'relative',
                  flexBasis: [null, '66%'],
                })}
              >
                <AssetQuoteSVG
                  css={mq({
                    width: scale('1.25rem', '1.5rem'),
                    maxHeight: '1.125rem',
                    color: modalData?.backgroundColorVariant,
                    marginBottom: scale('1rem', '1.5rem', 'space'),
                  })}
                />
                <HTMLContent
                  html={modalData?.descriptionHTML}
                  css={mq({
                    marginBottom: scale('1.5rem', '1.75rem'),
                  })}
                />
                <Text
                  css={mq({
                    color: modalData?.backgroundColorVariant,
                    fontSize: scale('0.8rem', '0.9rem', 'font'),
                    fontWeight: t.fontWeights.bold,
                    ...t.textStyles.trackedCapsWide,
                  })}
                >
                  &ndash; {modalData?.nominator}
                </Text>
              </Flex>
              <button
                className="close-button"
                onClick={closeModal}
                css={{
                  position: 'absolute',
                  top: '1rem',
                  right: '1rem',
                }}
              >
                <AssetCloseSVG
                  aria-hidden
                  css={mq({
                    width: ['1.25rem', '1.75rem'],
                    height: ['1.25rem', '1.75rem'],
                  })}
                />
                <VisuallyHidden>Close</VisuallyHidden>
              </button>
            </div>
            <div
              css={mq({
                height: ['2rem', '3rem'],
                backgroundColor: modalData?.backgroundColorVariant,
                position: 'relative',
                paddingLeft: scale('2rem', '3rem', 'space'),
                paddingRight: scale('2rem', '3rem', 'space'),
              })}
            >
              <AssetHeroTextLogoSVG
                css={mq({
                  width: ['8rem', '9rem'],
                  position: 'absolute',
                  bottom: ['1rem', '2rem'],
                  left: ['50%', 'inherit'],
                  marginLeft: ['-4rem', 'inherit'],
                })}
              />
            </div>
          </DialogContent>
        </div>
      </DialogOverlay>
    </div>
  )
}

interface CardBaseInfoProps {
  name?: string
  occupation?: string
  location?: string
  nominator?: string
}
interface CardProps extends CardBaseInfoProps {
  messageExcerpt?: string
  index: number
  imageFluid?: FluidObject
  openModal: any
  setModalData: any
  descriptionHTML?: string
}

const CardBaseInfo = ({
  name,
  occupation,
  location,
  nominator,
  ...props
}: CardBaseInfoProps) => (
  <Flex
    css={{
      flexDirection: 'column',
    }}
    {...props}
  >
    <Text
      css={mq({
        fontSize: scale('1.75rem', '2.25rem', 'font'),
      })}
    >
      {name}
    </Text>
    {occupation && (
      <Text
        css={mq({
          fontSize: scale('0.75rem', '1rem', 'font'),
          fontWeight: t.fontWeights.bold,
          marginTop: scale('1rem', '1.25rem', 'space'),
          ...t.textStyles.trackedCapsWide,
        })}
      >
        {occupation}
      </Text>
    )}
    {location && (
      <Flex
        css={mq({
          alignItems: 'center',
          marginTop: scale('0.75rem', '1rem', 'space'),
        })}
      >
        <AssetLocationSVG
          css={mq({
            width: '1rem',
            height: '1.25rem',
            marginRight: '0.5rem',
          })}
        />
        <Text
          css={mq({
            fontSize: scale('0.85rem', '1rem', 'font'),
            fontWeight: t.fontWeights.medium,
          })}
        >
          {location}
        </Text>
      </Flex>
    )}
    {nominator && (
      <Text
        css={mq({
          marginTop: scale('0.65rem', '0.75rem', 'space'),
          fontSize: scale('0.85rem', '1rem', 'font'),
          fontWeight: t.fontWeights.medium,
        })}
      >
        Nominated by {nominator}
      </Text>
    )}
  </Flex>
)

const Card = ({
  index,
  openModal,
  setModalData,
  name,
  occupation,
  location,
  messageExcerpt,
  imageFluid,
  descriptionHTML,
  nominator,
}: CardProps) => {
  const backgroundColorVariant = useMemo(() => getBackgroundColor(index), [
    index,
  ])

  const handleClick = () => {
    setModalData({
      name,
      occupation,
      location,
      nominator,
      descriptionHTML,
      backgroundColorVariant,
    })
    openModal()
  }

  return (
    <Flex
      css={mq({
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
      })}
    >
      {imageFluid && (
        <GatsbyImage
          fluid={imageFluid}
          css={mq({
            position: 'absolute !important',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          })}
        />
      )}
      <div
        css={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          backgroundColor: backgroundColorVariant,
          opacity: 0.7,
        }}
      />

      <Flex
        css={mq({
          flexDirection: 'column',
          position: 'relative',
          paddingTop: scale('2rem', '3rem', 'space'),
          paddingLeft: scale('2rem', '3rem', 'space'),
          paddingRight: scale('2rem', '3rem', 'space'),
          color: t.colors.white,
          minHeight: ['18rem', '25rem'],
        })}
      >
        <div
          css={{
            flexGrow: 1,
          }}
        >
          <CardBaseInfo
            name={name}
            occupation={occupation}
            location={location}
          />
        </div>

        <div
          css={mq({
            paddingTop: '1rem',
            paddingBottom: scale('2rem', '3rem', 'space'),
            marginTop: 'auto',
          })}
        >
          <AssetQuoteSVG
            css={mq({
              width: '1rem',
              height: '.75rem',
              marginBottom: scale('0.35rem', '0.5rem', 'space'),
              color: t.colors.white,
            })}
          />
          <Text
            css={{
              fontWeight: t.fontWeights.medium,
            }}
          >
            {messageExcerpt}
          </Text>
        </div>
      </Flex>
      <div
        css={mq({
          marginTop: 'auto',
          height: ['2rem', '3rem'],
          backgroundColor: backgroundColorVariant,
          position: 'relative',
          paddingLeft: scale('2rem', '3rem', 'space'),
          paddingRight: scale('2rem', '3rem', 'space'),
        })}
      >
        <button
          css={mq({
            backgroundColor: t.colors.yellow['40'],
            fontFamily: t.fonts.sans,
            fontSize: scale('0.8rem', '0.9rem', 'font'),
            color: t.colors.maroon[20],
            fontWeight: t.fontWeights.bold,
            lineHeight: t.lineHeights.solid,
            padding: '.5rem 1rem',
            position: 'absolute',
            bottom: ['1rem', '2rem'],
            ...t.textStyles.trackedCapsWide,
          })}
          onClick={handleClick}
        >
          read full story
        </button>
      </div>
    </Flex>
  )
}

type ColumnsProps = {
  children: React.ReactNode
  count: number[]
  space: (number | string)[]
  className?: string
}

const Columns = ({ children, count = [1], space, className }: ColumnsProps) => {
  return (
    <div
      className={className}
      css={mq({
        marginTop: negateScale(space),
      })}
    >
      <Flex
        as="ul"
        css={mq({
          marginLeft: negateScale(space),
          flexWrap: 'wrap',
          justifyContent: 'center',
        })}
      >
        {React.Children.map(
          children,
          child =>
            child && (
              <li
                css={mq({
                  paddingTop: space,
                  paddingLeft: space,
                  width: count.map(x => `${100 / x}%`),
                })}
              >
                {child}
              </li>
            ),
        )}
      </Flex>
    </div>
  )
}

export type PageBodyRecipientsProps = Partial<ReturnType<typeof mapDataToProps>>

const INITIAL_RECIPIENTS = 12
const RECIPIENTS_PER_PAGE = 6

export const PageBodyRecipients = ({
  heading,
  textHTML,
}: PageBodyRecipientsProps) => {
  const [page, setPage] = useState(0)
  const incPage = () => setPage(state => state + 1)

  const [searchQuery, setSearchQuery] = useState('')

  const allRecipients = useRecipients()
  const recipients = searchQuery.trim()
    ? allRecipients.filter(recipient =>
        RegExp(searchQuery.replace(/(\W|\.)/g, ''), 'ig').test(recipient.name),
      )
    : allRecipients.slice(0, INITIAL_RECIPIENTS + page * RECIPIENTS_PER_PAGE)

  const [showDialog, setShowDialog] = useState(false)
  const [modalData, setModalData] = useState<ModalData | undefined>()

  const openModal = () => setShowDialog(true)
  const closeModal = () => setShowDialog(false)

  return (
    <>
      <BoundedView
        css={{
          maxWidth: t.sizes.x,
          backgroundColor: t.colors.tan['90'],
        }}
      >
        <Flex
          css={mq({
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: scale('2rem', '3rem', 'space'),
          })}
        >
          {heading && (
            <GreenBox
              css={mq({
                marginBottom: scale('2rem', '2rem', 'space'),
              })}
            >
              {heading}
            </GreenBox>
          )}
          {textHTML && (
            <HTMLContent
              html={textHTML}
              css={{ textAlign: 'center' }}
              componentOverrides={{
                p: () => props => (
                  <Text
                    {...props}
                    css={mq({
                      maxWidth: [null, '80ch'],
                      marginBottom: '1rem',
                      ...t.boxStyles.lastNoMargin,
                    })}
                  />
                ),
              }}
            />
          )}
        </Flex>
        <Flex
          css={{
            justifyContent: 'center',
            marginBottom: scale('2rem', '2rem', 'space'),
          }}
        >
          <VisuallyHidden>
            <label htmlFor="searchQuery">Search by first name</label>
          </VisuallyHidden>
          <div
            css={{
              width: '100%',
              maxWidth: '18rem',
              position: 'relative',
            }}
          >
            <AssetSearchSVG
              css={{
                color: t.colors.tan[80],
                position: 'absolute',
                top: '50%',
                left: '0.75rem',
                transform: 'translateY(-50%)',
                width: '1rem',
              }}
            />
            <input
              id="searchQuery"
              placeholder="Search by first name"
              type="search"
              name="searchQuery"
              onChange={event => setSearchQuery(event.target.value)}
              value={searchQuery}
              css={{
                backgroundColor: 'white',
                border: '1px solid',
                borderColor: t.colors.tan[80],
                borderRadius: '100em',
                color: t.colors.maroon[20],
                fontFamily: t.fonts.sans,
                fontSize: '1rem',
                lineHeight: t.lineHeights.solid,
                padding: '.5rem 1rem',
                textAlign: 'center',
                width: '100%',
                '&::placeholder': {
                  color: t.colors.tan[40],
                },
              }}
            />
          </div>
        </Flex>
        {recipients.length > 0 ? (
          <Columns
            count={[1, 2, 3]}
            space={scale('1rem', '1.75rem', 'space')}
            css={{
              maxWidth: t.sizes.l,
              margin: '0 auto',
            }}
          >
            {recipients.map((recipient, index) => (
              <Card
                key={recipient?.id}
                index={index + 1}
                name={recipient?.name}
                occupation={recipient?.occupation}
                location={recipient?.location}
                messageExcerpt={recipient?.messageExcerpt}
                descriptionHTML={recipient?.messageHTML}
                nominator={recipient?.nominator}
                imageFluid={recipient?.photoFluid}
                openModal={openModal}
                setModalData={setModalData}
              />
            ))}
          </Columns>
        ) : (
          <div css={mq({ marginTop: scale('0rem', '4rem', 'space') })}>
            <Heading
              css={mq({
                fontSize: scale('1.5rem', '2rem', 'font'),
                marginBottom: scale('1rem', '1.25rem', 'space'),
                textAlign: 'center',
              })}
            >
              No heroes found with that name
            </Heading>
            <Text css={{ textAlign: 'center' }}>
              Try a different name or check for misspellings.
            </Text>
          </div>
        )}
        {!searchQuery.trim() && recipients.length < allRecipients.length && (
          <Flex
            css={mq({
              marginTop: scale('2rem', '3rem', 'space'),
              alignItems: 'center',
              flexDirection: 'column',
            })}
          >
            <Button
              onClick={incPage}
              css={mq({
                marginBottom: scale('0.75rem', '1rem', 'space'),
              })}
            >
              See More
            </Button>
            <Text css={mq({ fontSize: scale('0.9rem', '1rem', 'font') })}>
              Showing {recipients.length} of {allRecipients.length}
            </Text>
          </Flex>
        )}
        <Modal
          modalData={modalData}
          showDialog={showDialog}
          closeModal={closeModal}
        />
      </BoundedView>
    </>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyRecipientsFragment>) => ({
  heading: data?.primary?.heading?.text,
  textHTML: getRichText(data.primary?.text),
})

export const query = graphql`
  fragment PageBodyRecipients on PrismicPageBodyRecipients {
    primary {
      heading {
        text
      }
      text {
        html
        text
      }
    }
  }
`
PageBodyRecipients.mapDataToProps = mapDataToProps

export default PageBodyRecipients
