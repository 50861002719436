import React from 'react'
import { useField } from 'formik'
import { Flex } from '@walltowall/system'

import { t } from '../theme'
import { Text } from './Text'
import { ErrorMessage } from './ErrorMessage'

type TextAreaProps = {
  label: string
  name: string
  className?: string
}

export const TextArea = ({ label, name, className }: TextAreaProps) => {
  const [field, meta] = useField(name)

  return (
    <Flex
      className={className}
      as="label"
      css={{
        flexDirection: 'column',
        cursor: 'text',
        flex: '1 1 auto',
        marginBottom: ['1rem', '1.5rem'],
      }}
    >
      <Text css={{ marginBottom: '.75rem', fontWeight: t.fontWeights.medium }}>
        {label}
      </Text>
      <textarea
        name={name}
        {...field}
        css={{
          backgroundColor: t.colors.white,
          resize: 'vertical',
          border: '1px solid',
          borderColor: t.colors.tan[80],
          borderRadius: '1rem',
          color: t.colors.maroon[20],
          fontFamily: t.fonts.sans,
          fontSize: '1rem',
          height: '6em',
          lineHeight: t.lineHeights.copy,
          margin: '0 -.5rem',
          padding: '.5rem 1rem',
          appearance: 'none',
          '&::placeholder': {
            color: t.colors.tan[80],
          },
        }}
      />
      {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Flex>
  )
}
