import React from 'react'
import { useField } from 'formik'
import { Flex } from '@walltowall/system'

import { t, mq, scale } from '../theme'
import { Text } from './Text'
import { ErrorMessage } from './ErrorMessage'

type TextInputProps = {
  label: string
  name: string
  placeholder?: string
  type?: string
  className?: string
}

export const TextInput = ({
  label,
  name,
  placeholder,
  type = 'text',
  className,
}: TextInputProps) => {
  const [field, meta] = useField(name)

  return (
    <Flex
      className={className}
      as="label"
      css={mq({
        flexDirection: 'column',
        cursor: 'text',
        flex: '1 1 auto',
        marginBottom: '1.75rem',
      })}
    >
      <Text
        css={mq({
          fontSize: scale('1rem', '1.1rem', 'font'),
          marginBottom: '.75rem',
          fontWeight: t.fontWeights.medium,
        })}
      >
        {label}
      </Text>
      <input
        placeholder={placeholder}
        type={type}
        {...field}
        css={{
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: t.colors.tan[80],
          borderRadius: '100em',
          color: t.colors.maroon[20],
          fontFamily: t.fonts.sans,
          fontSize: '1rem',
          lineHeight: t.lineHeights.solid,
          margin: '0 -.5rem',
          padding: '.5rem 1rem',

          '&::placeholder': {
            color: t.colors.tan[80],
          },
        }}
      />
      {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Flex>
  )
}
