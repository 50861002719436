import React from 'react'
import { Flex } from '@walltowall/system'

import { t, mq, scale } from '../theme'

import { BoundedView } from '../components/BoundedView'
import { Anchor } from '../components/Anchor'

import { ReactComponent as AssetEqualHousingLender } from '../assets/logo-equal-housing-lender.svg'
import { ReactComponent as AssetLogoTextSVG } from '../assets/logo-text.svg'
import { ReactComponent as AssetIconFacebookSVG } from 'src/assets/icon-facebook.svg'
import { ReactComponent as AssetIconInstagramSVG } from 'src/assets/icon-instagram.svg'
import { ReactComponent as AssetIconLinkedinSVG } from 'src/assets/icon-linkedin.svg'
import { ReactComponent as AssetIconTwitterSVG } from 'src/assets/icon-twitter.svg'
import AssetTextureWarriorMobile from '../assets/texture-warrior-mobile.svg'
import { Link, LinkProps } from '../components/Link'

export type PageBodyFooter = {}

type SocialItemProps = {
  href: LinkProps['href']
  svg: React.ComponentType
}

type LinkItemProps = {
  href: LinkProps['href']
  children: string
}

const LinkItem = ({ href, children }: LinkItemProps) => (
  <li
    css={{
      marginRight: '1rem',
      marginBottom: '0.25rem',
    }}
  >
    <Anchor
      href={href}
      css={{
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
          color: t.colors.white,
        },
      }}
    >
      {children}
    </Anchor>
  </li>
)

const SocialItem = ({ href, svg: SVGComp, ...props }: SocialItemProps) => (
  <li css={{ marginRight: '1rem' }} {...props}>
    <Link href={href} target="_blank" rel="nofollow noreferer">
      <SVGComp
        css={{
          display: 'block',
          fill: t.colors.tan[40],
          width: '1.4rem',
          height: '1.4rem',
          '*': {
            transition: `fill ${t.transitionDurations.normal}`,
          },
          '&:hover': {
            '*': {
              fill: t.colors.gray[70],
            },
          },
        }}
      />
    </Link>
  </li>
)

type SideProps = {
  children: React.ReactNode
}

const Side = ({ children }: SideProps) => {
  return (
    <div
      css={mq({
        display: 'flex',
        alignItems: 'center',
        marginBottom: ['1rem', 0],
        fontSize: '0.8rem',

        '&:last-child': {
          textAlign: [null, 'right'],
          marginBottom: 0,
        },
      })}
    >
      {children}
    </div>
  )
}

export const PageBodyFooter = ({}: PageBodyFooter) => {
  return (
    <footer>
      <BoundedView
        css={mq({
          paddingTop: scale('2.4rem', '3.375rem', 'space'),
          paddingBottom: scale('2.4rem', '3.375rem', 'space'),
          backgroundColor: t.colors.maroon[10],
          color: t.colors.gray[70],
          backgroundPosition: 'bottom center',
          backgroundSize: 'cover',
          backgroundImage: `url(${AssetTextureWarriorMobile})`,

          '@media print': {
            display: 'none',
          },
        })}
      >
        <Flex
          css={mq({
            flexDirection: 'column',
            alignItems: ['flex-start', 'center'],
          })}
        >
          <Link
            href="https://hawaiinational.bank"
            target="_blank"
            rel="nofollow noreferrer"
            css={mq({
              display: 'block',
              maxWidth: '30rem',
              marginBottom: scale('1.4rem', '2.4rem', 'space'),
              width: '100%',
            })}
          >
            <AssetLogoTextSVG
              css={{
                maxHeight: '1.5rem',
                display: 'block',
                color: t.colors.white,
              }}
            />
          </Link>
          <Flex
            as="ul"
            css={mq({
              marginBottom: scale('1rem', '2rem', 'space'),
              fontSize: '1rem',
              marginRight: '-1rem',
              flexWrap: 'wrap',
              maxWidth: '30rem',
              justifyContent: [null, 'center'],
            })}
          >
            <LinkItem href="https://hawaiinational.bank/business/">
              Business
            </LinkItem>
            <LinkItem href="https://hawaiinational.bank/personal/">
              Personal
            </LinkItem>
            <LinkItem href="https://hawaiinational.bank/locations/">
              Locations
            </LinkItem>
            <LinkItem href="https://hawaiinational.bank/contact/">
              Contact
            </LinkItem>
            <LinkItem href="https://ebanking.hawaiinational.bank/">
              Sign In
            </LinkItem>
            <LinkItem href="https://hawaiinational.bank/about-us/">
              About Us
            </LinkItem>
            <LinkItem href="https://hawaiinational.bank/careers/">
              Careers
            </LinkItem>
            <LinkItem href="https://hawaiinational.bank/faq/">FAQ</LinkItem>
            <LinkItem href="https://hawaiinational.bank/privacy-security/">
              Privacy & Security
            </LinkItem>
          </Flex>
          <Flex
            as="ul"
            css={mq({
              justifyContent: [null, 'center'],
              marginRight: '-1rem',
            })}
          >
            <SocialItem
              svg={AssetIconFacebookSVG}
              href="https://www.facebook.com/hawaiinational"
            />
            <SocialItem
              svg={AssetIconInstagramSVG}
              href="https://instagram.com/hawaiinationalbank"
            />
            <SocialItem
              svg={AssetIconLinkedinSVG}
              href="https://www.linkedin.com/company/hawaii-national-bank"
            />
            <SocialItem
              svg={AssetIconTwitterSVG}
              href="https://twitter.com/hawaiinational"
            />
          </Flex>
        </Flex>
      </BoundedView>
      <BoundedView
        maxWidth={t.sizes.l}
        css={mq({
          paddingTop: scale('1.5rem', '1.5rem', 'space'),
          paddingBottom: scale('1.5rem', '1.5rem', 'space'),
          backgroundColor: t.colors.maroon[0],
          color: t.colors.gray[70],

          '@media print': {
            display: 'none',
          },
        })}
      >
        <Flex
          css={mq({
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
            margin: '0 auto',
            alignItems: ['flex-start', 'center'],
          })}
        >
          <Side>
            <AssetEqualHousingLender
              css={{ width: '2rem', height: '2rem', marginRight: '.5rem' }}
            />
            <p>
              Equal Opportunity Lender
              <br />
              Member FDIC/Federal Reserve System
            </p>
          </Side>
          <Side>
            <small>
              &copy; {new Date().getFullYear()} Hawaii National Bank. All rights
              reserved.
              <br />
              Website designed and developed by{' '}
              <Anchor
                href="https://walltowall.com"
                css={{ color: t.colors.white, textDecoration: 'none' }}
              >
                Wall-to-Wall Studios
              </Anchor>
              .
            </small>
          </Side>
        </Flex>
      </BoundedView>
    </footer>
  )
}
