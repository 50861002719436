import React from 'react'
import { Helmet } from 'react-helmet'
import { DevRefreshButton } from './DevRefreshButton'

import { t, mq, scale } from '../theme'

export type LayoutProps = {
  children?: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => (
  <>
    <Helmet
      titleTemplate="%s | Hawaii National Bank"
      defaultTitle="Hometown Heroes | Hawaii National Bank"
    >
      <html lang="en" />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cloud.typography.com/6033652/6306212/css/fonts.css"
      />
    </Helmet>
    {process.env.NODE_ENV === 'development' && <DevRefreshButton />}
    <div
      css={mq({
        fontFamily: t.fonts.sans,
        lineHeight: t.lineHeights.copy,
        backgroundColor: t.colors.gray[90],
        color: t.colors.maroon[20],
        fontSize: scale('1rem', '1.2rem', 'font'),
      })}
    >
      {children}
    </div>
  </>
)
