import React from 'react'

import { t, mq, scale } from '../theme'
import { ClippedView, ClippedViewProps } from './ClippedView'

const variants = {
  primary: {
    fontFamily: t.fonts.sans,
    fontSize: scale('2rem', '3.25rem', 'font'),
    fontWeight: t.fontWeights.normal,
    lineHeight: t.lineHeights.title,
    clipTop: '0.28em',
    clipBottom: '0.2em',
  },
  caps: {
    fontFamily: t.fonts.sans,
    fontSize: scale('1.125rem', '1.5rem', 'font'),
    fontWeight: t.fontWeights.semibold,
    lineHeight: t.lineHeights.title,
    clipTop: '0.23em',
    clipBottom: '0.21em',
    ...t.textStyles.trackedCapsWide,
  },
} as const

type HeadingAs = 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'

type HeadingProps = {
  as?: HeadingAs
  variant?: keyof typeof variants
  children?: React.ReactNode
  className?: string
  clipTop?: ClippedViewProps<HeadingAs>['clipTop']
  clipBottom?: ClippedViewProps<HeadingAs>['clipBottom']
  id?: string
}

export const Heading = ({
  as = 'h2',
  variant: variantName = 'primary',
  children,
  className,
  id,
}: HeadingProps) => {
  const { clipBottom, clipTop, ...variant } = variants[variantName]

  return (
    <ClippedView
      as={as}
      id={id}
      className={className}
      clipTop={clipTop}
      clipBottom={clipBottom}
      css={mq(variant)}
    >
      {children}
    </ClippedView>
  )
}
