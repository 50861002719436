import React from 'react'
import { graphql } from 'gatsby'
import { Flex } from '@walltowall/system'
import { getRichText } from '@walltowall/helpers'

import { t, mq, scale } from '../theme'
import { MapDataToPropsArgs } from '../types'
import { PageBodyTermsFragment } from '../graphqlTypes'

import { BoundedView } from '../components/BoundedView'
import { GreenBox } from '../components/GreenBox'
import { Text } from '../components/Text'
import { Anchor } from '../components/Anchor'
import { HTMLContent } from '../components/HTMLContent'

export type PageBodyTermsProps = Partial<ReturnType<typeof mapDataToProps>>

export const PageBodyTerms = ({ text }: PageBodyTermsProps) => (
  <BoundedView
    maxWidth={t.sizes.s}
    css={mq({
      backgroundColor: t.colors.white,
      maxWidth: t.sizes.x,
    })}
  >
    <Flex
      css={mq({
        flexDirection: 'column',
        alignItems: 'center',
      })}
    >
      <HTMLContent
        html={text}
        css={{ textAlign: 'center' }}
        componentOverrides={{
          h1: () => props => (
            <GreenBox
              css={mq({
                marginBottom: scale('2rem', '2rem', 'space'),
              })}
              {...props}
            />
          ),
          p: () => props => <Text {...props} />,
          a: () => props => <Anchor as="a" {...props} />,
        }}
      />
    </Flex>
  </BoundedView>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyTermsFragment>) => ({
  text: getRichText(data.primary?.text),
})

export const query = graphql`
  fragment PageBodyTerms on PrismicPageBodyTerms {
    primary {
      text {
        html
        text
      }
    }
  }
`

PageBodyTerms.mapDataToProps = mapDataToProps
export default PageBodyTerms
