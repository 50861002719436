import facepaint from 'facepaint'
import { linearScale as _linearScale } from 'styled-system-scale'

const sizes = {
  s: '48rem',
  m: '60rem',
  l: '80rem',
  x: '100rem',
} as const
const breakpoints = Object.values(sizes)
const rawSizes = (Object.keys(sizes) as (keyof typeof sizes)[]).reduce(
  (acc, key) => {
    acc[key] = Number.parseInt(sizes[key].replace('rem', ''))
    return acc
  },
  {} as Record<keyof typeof sizes, number>,
)
const mediaQueries = (Object.keys(sizes) as (keyof typeof sizes)[]).reduce(
  (acc, key) => {
    acc[key] = `@media screen and (min-width: ${sizes[key]})`

    return acc
  },
  {} as Record<keyof typeof sizes, string>,
)

// Used for linearScale count
const count = breakpoints.length + 1

export const theme = {
  // Sizes
  breakpoints,
  sizes,
  rawSizes,

  // Colors
  colors: {
    black: '#000',
    gray: {
      10: '#333',
      70: '#b3b1b1',
      90: '#e7e7e7',
    },
    maroon: {
      0: '#1b1112',
      10: '#312525',
      20: '#423132',
    },
    yellow: {
      40: '#F4CB6E',
    },
    red: {
      10: '#500713',
      30: '#801731',
      '30-brand': '#a60250',
      40: '#c31446',
    },
    pink: {
      40: '#E66E64',
    },
    green: {
      40: '#C9C055',
      50: '#46c8a2',
      70: '#64BEA5',
    },
    tan: {
      20: '#423132',
      40: '#776f65',
      80: '#dfddd7',
      90: '#f5f5f3',
      '90-bg': '#ebe9e8',
    },
    gold: {
      70: '#d1c194',
    },
    beige: {
      80: '#e0ded8',
      90: '#ecebe7',
    },
    white: '#fff',
  },

  // Fonts
  fonts: {
    sans: '"Whitney A", "Whitney B", sans-serif',
    sansCond: '"Whitney Cond A", "Whitney Cond B", sans-serif',
    serif: 'Feijoa, serif',
  },
  fontWeights: {
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    heavy: 800,
    black: 900,
  },
  lineHeights: {
    solid: 1,
    title: 1.15,
    copy: 1.45,
  },

  // Z-Indicies
  zIndices: {
    base: 0,
    header: 2,
  },

  // Transitions
  transitionDurations: {
    slow: '300ms',
    normal: '200ms',
    fast: '100ms',
  },

  // Variants
  textStyles: {
    caps: {
      textTransform: 'uppercase',
    },
    lowercase: {
      textTransform: 'lowercase',
    },
    trackedCaps: {
      textTransform: 'uppercase',
      letterSpacing: '0.05em',
    },
    trackedCapsWide: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
  },
  boxStyles: {
    lastNoMargin: {
      '&:last-child': {
        marginBottom: 0,
        marginRight: 0,
      },
    },
    firstLastNoMargin: {
      '&:first-of-type': {
        marginTop: 0,
        marginLeft: 0,
      },
      '&:last-child': {
        marginBottom: 0,
        marginRight: 0,
      },
    },
  },

  // Media queries
  mediaQueries,
} as const
export { theme as t }

export type Theme = typeof theme

export const mq = facepaint(Object.values(mediaQueries))

export const linearScale = <T extends string | number>(
  min: T,
  max: T,
  _type?: 'font' | 'space',
) => _linearScale(min, max, { count })
export { linearScale as scale }
