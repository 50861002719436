import React from 'react'
import { View } from '@walltowall/system'

import { t, mq, scale } from '../theme'

import { ClippedView } from './ClippedView'

type GreenBoxAs = 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
type GreenBoxProps = {
  as?: GreenBoxAs
  children: string
}

export const GreenBox = ({ as = 'h2', children, ...props }: GreenBoxProps) => {
  return (
    <View
      as={as}
      {...props}
      css={mq({
        color: t.colors.white,
        backgroundColor: t.colors.green[50],
        padding: '.75rem 1.5rem',
        fontWeight: t.fontWeights.bold,
        display: 'inline-block',
        fontSize: scale('1.125rem', '1.2rem', 'font'),
        textAlign: 'center',
        lineHeight: t.lineHeights.solid,
        ...t.textStyles.trackedCapsWide,
      })}
    >
      <ClippedView clipTop="0.11em" clipBottom="0.13em">
        {children}
      </ClippedView>
    </View>
  )
}
