import React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { Flex } from '@walltowall/system'
import { getRichText } from '@walltowall/helpers'

import { t, mq, scale } from '../theme'
import { PageBodyColoredBoxesFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'

import { HTMLContent } from '../components/HTMLContent'
import { GreenBox } from '../components/GreenBox'
import { Text } from '../components/Text'
import { Heading } from '../components/Heading'
import { Anchor } from '../components/Anchor'
import { Link } from '../components/Link'

import { ReactComponent as AssetLogoInstagramSVG } from '../assets/logo-instagram.svg'

const PADDING = '2.5rem'

type ColoredBoxProps = {
  children: React.ReactNode
  heading?: string
  fluid?: FluidObject
}

const ColoredBox = ({ children, heading, fluid }: ColoredBoxProps) => (
  <Flex
    css={mq({
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      padding: PADDING,
      minHeight: ['15rem', null, null, '16rem'],
    })}
  >
    <GatsbyImage
      fluid={fluid}
      css={mq({
        position: 'absolute !important',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      })}
    />
    <Flex
      css={{
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        maxWidth: '50ch',
        margin: '0 auto',
      }}
    >
      <Heading
        as="h3"
        variant="caps"
        css={mq({ marginBottom: scale('1.5rem', '1.5rem', 'space') })}
      >
        {heading}
      </Heading>
      {children}
    </Flex>
  </Flex>
)

export type PageBodyColoredBoxesProps = Partial<
  ReturnType<typeof mapDataToProps>
>

export const PageBodyColoredBoxes = ({
  description,
  boxes,
}: PageBodyColoredBoxesProps) => (
  <div
    css={{
      maxWidth: t.sizes.x,
      color: t.colors.white,
      margin: '0 auto',
    }}
  >
    <Flex
      css={mq({
        flexDirection: ['column', 'row'],
      })}
    >
      <Flex
        css={mq({
          flex: [null, '1 1 0px'],
          backgroundColor: t.colors.maroon[10],
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <Flex
          css={mq({
            padding: PADDING,
            flexDirection: 'column',
            alignItems: 'center',
          })}
        >
          <HTMLContent
            html={description}
            css={{ textAlign: 'center', maxWidth: '55ch' }}
            componentOverrides={{
              h1: () => props => (
                <GreenBox
                  css={mq({ marginBottom: scale('2rem', '2rem', 'space') })}
                  {...props}
                />
              ),
              p: () => props => (
                <Text
                  css={mq({
                    marginBottom: scale('1.5rem', '1.5rem', 'space'),
                    ...t.boxStyles.lastNoMargin,
                  })}
                  {...props}
                />
              ),
            }}
          />
        </Flex>
      </Flex>

      <div
        css={mq({
          flex: [null, '1 1 0px'],
        })}
      >
        {boxes?.map(box => (
          <ColoredBox
            heading={box?.cardHeading}
            fluid={box?.backgroundImage}
            key={box.cardText}
          >
            <HTMLContent
              html={box?.cardText}
              css={mq({
                textAlign: 'center',
              })}
              componentOverrides={{
                h1: () => props => (
                  <GreenBox
                    css={mq({ marginBottom: scale('2rem', '2rem', 'space') })}
                    {...props}
                  />
                ),
                a: () => props => (
                  <Anchor
                    href="https://instagram.com/hawaiinationalbank"
                    css={{
                      color: t.colors.white,
                      fontWeight: t.fontWeights.semibold,
                    }}
                    {...props}
                  />
                ),
                p: () => props => (
                  <Text
                    css={
                      box?.showInstagramIcon && {
                        marginBottom: scale('1.5rem', '1.5rem', 'space'),
                      }
                    }
                    {...props}
                  />
                ),
              }}
            />

            {box?.showInstagramIcon && (
              <Link href="https://instagram.com/hawaiinationalbank">
                <AssetLogoInstagramSVG
                  css={mq({
                    display: 'block',
                    color: t.colors.white,
                    width: scale('2rem', '3rem'),
                  })}
                />
              </Link>
            )}
          </ColoredBox>
        ))}
      </div>
    </Flex>
  </div>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyColoredBoxesFragment>) => ({
  description: getRichText(data.primary?.description),
  boxes: data?.items?.map(item => ({
    backgroundImage: item?.background_image?.fluid,
    cardHeading: item?.card_heading?.text,
    cardText: getRichText(item?.card_text),
    showInstagramIcon: item?.show_instagram_icon,
  })),
})

export const query = graphql`
  fragment PageBodyColoredBoxes on PrismicPageBodyColoredBoxes {
    primary {
      description {
        html
        text
      }
    }
    items {
      background_image {
        fluid(maxWidth: 700) {
          ...GatsbyPrismicImageFluid
        }
      }
      card_heading {
        html
        text
      }
      card_text {
        html
        text
      }
      show_instagram_icon
    }
  }
`

PageBodyColoredBoxes.mapDataToProps = mapDataToProps
export default PageBodyColoredBoxes
