import React from 'react'

import { t } from '../theme'
import { Text } from './Text'

type ErrorMessageProps = {
  children: React.ReactNode
}

export const ErrorMessage = ({ children, ...props }: ErrorMessageProps) => {
  return (
    <Text
      css={{ color: t.colors.red[40], marginTop: '.5rem', fontSize: '.9rem' }}
      {...props}
    >
      {children}
    </Text>
  )
}
