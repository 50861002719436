import React from 'react'
import HTMLRenderer from 'react-html-renderer'

import { t, mq, scale } from '../theme'
import { Heading } from './Heading'
import { Anchor } from './Anchor'
import { Text } from './Text'

const baseHeadingCss = {
  marginTop: scale('2.25rem', '3.5rem'),
  marginBottom: scale('1.75rem', '2rem'),
  ...t.boxStyles.firstLastNoMargin,
}

const baseTextCss = {
  marginBottom: scale('1.5rem', '1.75rem'),
  ...t.boxStyles.lastNoMargin,
}

const components: React.ComponentProps<typeof HTMLRenderer>['components'] = {
  h1: props => <Heading {...props} as="h3" css={mq({ ...baseHeadingCss })} />,
  h2: props => <Heading {...props} as="h4" css={mq({ ...baseHeadingCss })} />,
  h3: props => <Heading {...props} as="h5" css={mq({ ...baseHeadingCss })} />,
  h4: props => <Heading {...props} as="h6" css={mq({ ...baseHeadingCss })} />,
  h5: props => <Heading {...props} as="h6" css={mq({ ...baseHeadingCss })} />,
  h6: props => <Heading {...props} as="h6" css={mq({ ...baseHeadingCss })} />,
  p: props => <Text {...props} css={mq({ ...baseTextCss })} />,
  ul: props => (
    <ul
      {...props}
      css={mq({
        ...baseTextCss,
        paddingLeft: scale('2rem', '3rem'),
        listStyle: 'disc',
      })}
    />
  ),
  ol: props => (
    <ol
      {...props}
      css={mq({
        ...baseTextCss,
        paddingLeft: scale('2rem', '3rem'),
        listStyle: 'decimal',
      })}
    />
  ),
  li: props => (
    <Text
      as="li"
      {...props}
      css={mq({
        ...baseTextCss,
        marginBottom: 0,
      })}
    />
  ),
  a: ({ href, ...props }) => <Anchor href={href!} {...props} />,
}

type HTMLContentProps = {
  html?: React.ComponentProps<typeof HTMLRenderer>['html']
  componentOverrides?: React.ComponentProps<
    typeof HTMLRenderer
  >['componentOverrides']
  className?: string
}

export const HTMLContent: React.FC<HTMLContentProps> = ({
  html,
  componentOverrides,
  className,
}) => (
  <div className={className} css={{ lineHeight: t.lineHeights.copy }}>
    <HTMLRenderer
      html={html}
      components={components}
      componentOverrides={componentOverrides}
    />
  </div>
)
